import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb el capell i el peu blanc quasi neu, a vegades un poc groguenc, sobretot si es toca. El capell pot mesurar fins a 15 cm de diàmetre, inicialment hemisfèric per tornar quasi pla i un poc deprimit al centre. Les làmines estan lliures, primer pàl·lides, després color carn clar i finalment marró fosc, amb l’aresta més blanca. El peu és cilíndric, un poc més gruixut a la base amb esquames disposades en capes per baix de l’anell. Aquest és blanc, ample, gruixut, llis per la part superior. A la part inferior té esquames grosses disposades de manera que sembla una roda de carro. Les espores són marró fosc en massa, ovalades, de 5-7 x 4-4,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      